<template>
    <bottom-popup ref="popup" class="Login-popup" v-bind="$attrs" :noContentTop="true">
        <div class="Login">
            <div class="close" @click="close"></div>
            <div class="content">
                <h1>Please register an account</h1>
                <p>This will save your watching history.</p>
                <div class="form" ref="form">
                    <div>
                        <input class="Email" type="text" placeholder="Email" maxlength="50" />
                    </div>
                    <div>
                        <input type="password" placeholder="Password" maxlength="20" />
                    </div>
                </div>
            </div>
            <div class="SignUp" @click="SignUp">Sign up</div>
            <div class="Login" @click="close">Log in</div>
        </div>
    </bottom-popup>
</template>

<script>
import BottomPopup from "../BottomPopup.vue";
export default {
    name: "Login",
    components: {
        BottomPopup
    },
    data() {
        return {};
    },
    methods: {
        open() {
            this.$refs.popup.openPopup();
        },
        close() {
            this.$refs.popup.closePopup();
        },
        itemClick(item) {
            this.current = item;
        },
        weedlyDiscount() {
            this.list.forEach((item) => {
                if (item.id == 1) {
                    item.price = "$17.09";
                    item.priceDetails = "$2.4 / day";
                    item.discount = "10% off Weedly";
                }
            });
        },
        formValidate() {
            return new Promise((r) => {
                let arr = [];
                this.$refs.form.childNodes.forEach((c) => {
                    const input = c.childNodes[0];
                    if (input.value) {
                        c.className = c.className.replace(" no", "");
                        if (input.className === "Email" && input.value.indexOf("@") < 0) {
                            c.className = c.className + " no";
                            arr.push(false);
                        }
                        arr.push(true);
                    } else {
                        if (c.className.indexOf("no") < 0) {
                            c.className = c.className + " no";
                        }
                        arr.push(false);
                    }
                });
                if (arr.every((a) => a)) {
                    r(true);
                }
            });
        },
        SignUp() {
            this.formValidate(() => {
                console.log(123);
            });
        },
        CardPayment() {
            this.close();
            this.$router.push({ name: "pay" });
        }
    }
};
</script>

<style lang="scss" scoped>
.Login-popup {
    ::v-deep .popup-content {
        background: #202028;
    }
    .Login {
        position: relative;
        height: 352px;
        box-sizing: content-box;
        width: 100%;
        overflow: hidden;
        .close {
            position: absolute;
            right: 0;
            top: 0;
            background: url("../MHeader/images/close.png") 0 0 / 24px no-repeat;
            width: 24px;
            height: 24px;
        }
        .content {
            h1 {
                font-size: 20px;
                line-height: 28px;
                font-weight: normal;
                margin: 0;
                margin-top: 16px;
            }
            p {
                font-size: 12px;
                line-height: 17px;
                margin-top: 12px;
                margin-bottom: 32px;
            }
            .form {
                & > div {
                    & + div {
                        margin-top: 15px;
                    }
                }
                input {
                    width: 100%;
                    border: none;
                    background: #ffffff0f;
                    height: 46px;
                    border-radius: 4px;
                    outline: none;
                    color: #fff;
                    padding: 13px 16px;
                }
            }
        }

        .SignUp {
            background: #d45252;
            height: 44px;
            border-radius: 8px;
            text-align: center;
            line-height: 44px;
            font-size: 16px;
            margin-top: 24px;
        }

        .Login {
            height: 44px;
            border-radius: 8px;
            text-align: center;
            line-height: 44px;
            font-size: 13px;
            margin-top: 5px;
            color: #ffffff80;
        }

        .no {
            position: relative;
            input {
                border: 1px solid #d45252 !important;
            }
            &::after {
                content: "Please check";
                display: block;
                color: #d45252;
                font-size: 10px;
                position: absolute;
                left: 0;
                bottom: -14px;
            }
        }
    }
}
</style>
