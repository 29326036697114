<template>
    <bottom-popup ref="popup" class="Unlock-popup" v-bind="$attrs">
        <div class="Unlock">
            <div class="close" @click="close"></div>
            <div class="title"><span></span>Unlock Subsequent Episodes</div>
            <div class="Package" :class="{ act: current.id == item.id }" v-for="(item, i) in list" :key="i" @click="itemClick(item)">
                <div class="price">
                    {{ item.price }} <span>{{ item.priceDetails }}</span>
                </div>
                <div class="info">
                    {{ item.info }}
                </div>
                <div class="tips">{{ item.tips }}</div>
                <div class="discount" v-if="item.discount">{{ item.discount }}</div>
            </div>
            <div class="payMent" @click="CardPayment">Card Payment</div>
        </div>
    </bottom-popup>
</template>

<script>
import BottomPopup from "../BottomPopup.vue";
export default {
    name: "Unlock",
    components: {
        BottomPopup
    },
    data() {
        return {
            current: {
                id: 2
            },
            list: [
                {
                    price: "$18.99",
                    priceDetails: "$2.7 / day",
                    info: "Weekly Subscription (Unlock all series for 7 days)",
                    tips: "Auto renewal - Cancel anytime",
                    id: 1
                },
                {
                    price: "$22.99",
                    priceDetails: "$0.7 / day",
                    info: "Month Subscription (Unlock all series for 30 days)",
                    tips: "Auto renewal - Cancel anytime",
                    id: 2,
                    discount: "50% off First Month"
                },
                {
                    price: "$159.99",
                    priceDetails: "$0.4 / day",
                    info: "Year Subscription (Unlock all series for 365 days)",
                    tips: "Auto renewal - Cancel anytime",
                    id: 3
                }
            ]
        };
    },
    methods: {
        open() {
            this.$refs.popup.openPopup();
        },
        close() {
            this.$refs.popup.closePopup();
        },
        itemClick(item) {
            this.current = item;
        },
        weedlyDiscount() {
            this.list.forEach((item) => {
                if (item.id == 1) {
                    item.price = "$17.09";
                    item.priceDetails = "$2.4 / day";
                    item.discount = "10% off Weedly";
                }
            });
        },
        CardPayment() {
            this.close();
            this.$router.push({ name: "pay" });
        }
    }
};
</script>

<style lang="scss" scoped>
.Unlock-popup {
    ::v-deep .popup-content {
        background: url("./images/UnlockBg.png") 0 0 / 100% no-repeat, #000;
    }
    .Unlock {
        position: relative;
        height: 535px;
        width: 100%;
        overflow: hidden;
        .close {
            position: absolute;
            right: 0;
            top: 0;
            background: url("../MHeader/images/close.png") 0 0 / 24px no-repeat;
            width: 24px;
            height: 24px;
        }
        .title {
            height: 29px;
            margin-top: 61px;
            margin-bottom: 44px;
            font-family: Crimson Text;
            font-size: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                display: inline-block;
                width: 26px;
                height: 26px;
                background: url("../../images/lock.png") 0 0 / cover no-repeat;
                margin-right: 9px;
            }
        }
        .Package {
            position: relative;
            overflow: hidden;
            width: 100%;
            height: 89px;
            padding: 7px 0;
            background: url("./images/itemBg.png") 50% 50% / cover no-repeat;
            border: 3px solid transparent;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            & + .Package {
                margin-top: 16px;
            }
            &.act {
                background: url("./images/itemBgAct.png") 50% 50% / cover no-repeat;
                border: 3px solid #ecb671;
            }
            .price {
                margin: 0 16px 6px;
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                height: 22px;
                span {
                    display: inline-block;
                    font-size: 11px;
                    margin-left: 10px;
                }
            }
            .info {
                margin: 0px 11px 4px 11px;
                display: inline-block;
                height: 22px;
                line-height: 22px;
                background: #ffd094;
                color: #3f1f25;
                font-size: 10px;
                padding: 0 5px;
            }
            .tips {
                font-size: 10px;
                height: 14px;
                line-height: 14px;
                margin: 0 16px 0px;
                color: #ffffff80;
            }
            .discount {
                background: #ff24bd;
                font-size: 12px;
                height: 24px;
                line-height: 24px;
                padding: 0 6px;
                position: absolute;
                right: 0;
                top: 0;
                border-radius: 0 7px 0 7px;
            }
        }
        .payMent {
            background: #d45252;
            height: 44px;
            border-radius: 8px;
            text-align: center;
            line-height: 44px;
            font-size: 16px;
            margin-top: 32px;
        }
    }
}
</style>
