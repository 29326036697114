import Vue from "vue";
import VueRouter from "vue-router";
import home from "../views/home.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: home,
        meta: {
            keepAlive: true
        }
    },
    {
        path: "/followList",
        name: "followList",
        component: () => import(/* webpackChunkName: "about" */ "../views/followList.vue")
    },
    {
        path: "/play",
        name: "play",
        component: () => import(/* webpackChunkName: "about" */ "../views/play.vue")
    },
    {
        path: "/pay",
        name: "pay",
        component: () => import(/* webpackChunkName: "about" */ "../views/pay.vue")
    }
];

const router = new VueRouter({
    mode: "history",
    routes
});

export default router;
