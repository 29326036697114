<template>
    <div class="Coupon_wrap" v-if="visable">
        <div class="Coupon">
            <h1>Congratulations!</h1>
            <div class="info">You've earned an extra <span>10%</span> off your first weekly subscription!</div>
            <div class="price">
                <div>
                    <h2>$18.99</h2>
                    <p>ORIGINAL</p>
                </div>
                <div>
                    <h2>$17.09</h2>
                    <p>NOW</p>
                </div>
            </div>
            <div class="tips">Preferential benefits</div>
        </div>
        <div class="btn">To Pay</div>
        <div class="close" @click="closeClick"></div>
    </div>
</template>

<script>
export default {
    name: "Coupon",
    data() {
        return {
            visable: false,
            num: 0
        };
    },
    methods: {
        open() {
            this.visable = true;
        },
        close() {
            this.visable = false;
        },
        closeClick() {
            if (this.num >= 1) {
                this.visable = false;
            }
            this.num += 1;
        }
    },
    watch: {
        visable(v) {
            if (!v) {
                this.num = 0;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.Coupon_wrap {
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .Coupon {
        width: 100%;
        height: 330px;
        background: url("./images/background.png") 50% bottom / 500px no-repeat;
        margin-top: 26%;
        position: relative;
        h1 {
            position: absolute;
            height: 28px;
            font-size: 26px;
            top: 26%;
            left: 0;
            right: 0;
            margin: auto;
            text-align: center;
            color: transparent;
            background: linear-gradient(90deg, #ffffff 0%, #fffbb5 100%);
            background-clip: text;
        }
        .info {
            width: 243px;
            position: absolute;
            font-size: 16px;
            top: 37%;
            left: 0;
            right: 0;
            margin: auto;
            text-align: center;
            span {
                color: #f7ff58;
            }
        }
        .price {
            position: absolute;
            width: 247px;
            height: 91px;
            left: 0;
            right: 0;
            bottom: 13%;
            margin: 0 auto;
            display: flex;
            & > div {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                &:nth-child(1) {
                    color: #d97b63;
                    h2 {
                        font-size: 24px;
                    }
                }
                &:nth-child(2) {
                    color: #e3390f;
                }
            }
            h2 {
                font-size: 31px;
                text-align: center;
                margin: 0;
                margin-bottom: 2px;
            }
            p {
                font-size: 12px;
                text-align: center;
            }
        }
        .tips {
            font-size: 14px;
            color: #ffffff99;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 6%;
            margin: 0 auto;
            text-align: center;
        }
    }

    .btn {
        width: 232px;
        height: 46px;
        font-size: 20px;
        line-height: 46px;
        background: linear-gradient(90deg, #ff4f9b 0%, #ff5a23 100%);
        border-radius: 50px;
        margin: 0 auto;
        text-align: center;
        border: 0.5px solid;
        border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffa5a5 100%);
        box-shadow: 0px 0 0.11rem 0.05333rem #ffffff99 inset;
        margin-top: 16px;
    }
    .close {
        width: 24px;
        height: 24px;
        margin: 0 auto;
        margin-top: 16px;
        background: url("../MHeader/images/close.png") 0 0 / cover no-repeat;
    }
}
</style>
