<template>
    <bottom-popup ref="popup" class="Congratulations-popup" v-bind="$attrs" :noContentTop="true">
        <div class="Congratulations">
            <div class="close" @click="close"></div>
            <div class="content">
                <p>Congratulations!</p>
                <p>Now you have unlocked all series.</p>
            </div>
            <div class="Continue" @click="close">Continue</div>
        </div>
    </bottom-popup>
</template>

<script>
import BottomPopup from "../BottomPopup.vue";
export default {
    name: "Congratulations",
    components: {
        BottomPopup
    },
    data() {
        return {
            openlg: false
        };
    },
    inject: ["openLogin"],
    methods: {
        open(openlg) {
            this.openlg = openlg || false;
            this.$refs.popup.openPopup();
        },
        close() {
            this.$refs.popup.closePopup();
            if (this.openlg) {
                setTimeout(() => {
                    this.openLogin();
                }, 3000);
            }
        },
        itemClick(item) {
            this.current = item;
        },
        weedlyDiscount() {
            this.list.forEach((item) => {
                if (item.id == 1) {
                    item.price = "$17.09";
                    item.priceDetails = "$2.4 / day";
                    item.discount = "10% off Weedly";
                }
            });
        },
        CardPayment() {
            this.close();
            this.$router.push({ name: "pay" });
        }
    }
};
</script>

<style lang="scss" scoped>
.Congratulations-popup {
    ::v-deep .popup-content {
        background: #202028;
    }
    .Congratulations {
        position: relative;
        height: 278px;
        width: 100%;
        overflow: hidden;
        .close {
            position: absolute;
            right: 0;
            top: 0;
            background: url("../MHeader/images/close.png") 0 0 / 24px no-repeat;
            width: 24px;
            height: 24px;
        }

        .content {
            width: 271px;
            height: 130px;
            margin: 0 auto;
            margin-top: 46px;
            background: url("./images/bg.png") 50% 0 / 83px no-repeat;
            padding-top: 90px;
            p {
                font-size: 14px;
                text-align: center;
                line-height: 20px;
            }
        }

        .Continue {
            background: #d45252;
            height: 44px;
            border-radius: 8px;
            text-align: center;
            line-height: 44px;
            font-size: 16px;
            margin-top: 32px;
        }
    }
}
</style>
