<template>
    <div class="home">
        <MHeader> <img class="logo" src="../images/logo.png" alt="" />AvantShort </MHeader>
        <div class="content">
            <div class="headerSwiper">
                <swiper class="swiper" :options="headerSwiperOptions" v-if="bannerArr.length > 0">
                    <swiper-slide v-for="(item, i) in bannerArr" :key="i">
                        <div class="item" @click="goPlay(item)"></div>
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
            </div>
            <div class="box NewRelease">
                <div class="title">New Release</div>
                <div class="box-content">
                    <swiper class="swiper" :options="NRSwiperOptions">
                        <swiper-slide v-for="(item, i) in NRArr" :key="i">
                            <div class="item">
                                <div class="intr">
                                    <div class="title">{{ item.title }}</div>
                                    <div class="info">{{ item.info }}</div>
                                    <div class="btn">Play Now</div>
                                </div>
                                <div class="poster"></div>
                            </div>
                        </swiper-slide>
                        <div class="swiper-pagination" slot="pagination"></div>
                    </swiper>
                </div>
            </div>

            <div class="box ComingSoon">
                <div class="title">Coming Soon</div>
                <div class="box-content">
                    <swiper class="swiper" :options="CSSwiperOptions">
                        <swiper-slide v-for="(item, i) in CSArr" :key="i">
                            <div class="item">
                                <div class="intr">
                                    <div class="title">{{ item.title }}</div>
                                    <div class="info">{{ item.info }}</div>
                                    <div class="releaseTime">
                                        <span>Release time: 10-26</span>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </swiper-slide>
                        <div class="swiper-pagination" slot="pagination"></div>
                    </swiper>
                </div>
            </div>

            <div class="LAFS LAFS1">
                <div class="LAFS_top">
                    <div>
                        <p class="title">Total 20</p>
                        <p class="info">698M People Watched</p>
                        <div class="more">More</div>
                    </div>
                    <div>
                        <img src="../images/LAFSTop.png" alt="" />
                    </div>
                </div>
                <div class="LAFS_bottom">
                    <div>
                        <div></div>
                        <p>The good The good</p>
                    </div>
                    <div>
                        <div></div>
                        <p>The good The good</p>
                    </div>
                    <div>
                        <div></div>
                        <p>The good The good</p>
                    </div>
                    <div>
                        <div></div>
                        <p>The good The good</p>
                    </div>
                    <div>
                        <div></div>
                        <p>The good The good</p>
                    </div>
                    <div>
                        <div></div>
                        <p>The good The good</p>
                    </div>
                </div>
            </div>

            <div class="LAFS LAFS2">
                <div class="LAFS_top">
                    <div>
                        <p class="title">Total 20</p>
                        <p class="info">698M People Watched</p>
                        <div class="more">More</div>
                    </div>
                    <div>
                        <img src="../images/LAFSTop.png" alt="" />
                    </div>
                </div>
                <div class="LAFS_bottom">
                    <div>
                        <div></div>
                        <p>The good The good</p>
                    </div>
                    <div>
                        <div></div>
                        <p>The good The good</p>
                    </div>
                    <div>
                        <div></div>
                        <p>The good The good</p>
                    </div>
                    <div>
                        <div></div>
                        <p>The good The good</p>
                    </div>
                    <div>
                        <div></div>
                        <p>The good The good</p>
                    </div>
                    <div>
                        <div></div>
                        <p>The good The good</p>
                    </div>
                </div>
            </div>

            <div class="box SecondChance">
                <div class="title">
                    Second Chance
                    <div class="btn">More</div>
                </div>
                <div class="box-content">
                    <swiper class="swiper" :options="SCSwiperOptions">
                        <swiper-slide v-for="(item, i) in SCArr" :key="i">
                            <div class="item"></div>
                            <div class="title">
                                {{ item.title }}
                            </div>
                        </swiper-slide>
                        <div class="swiper-pagination" slot="pagination"></div>
                    </swiper>
                </div>
            </div>

            <div class="box MoreRecommended">
                <div class="title">More Recommended</div>
                <div class="box-content">
                    <div>
                        <div class="poster"></div>
                        <p>The good The good The good</p>
                    </div>
                    <div>
                        <div class="poster"></div>
                        <p>The good The good The good</p>
                    </div>
                    <div>
                        <div class="poster"></div>
                        <p>The good The good The good</p>
                    </div>
                    <div>
                        <div class="poster"></div>
                        <p>The good The good The good</p>
                    </div>
                </div>
                <div class="more">Load More</div>
            </div>

            <footer>
                <div>
                    <div>Privacy Policy</div>
                    <div>Terms of Service</div>
                </div>
                <div>All rights reserved. 2024 Avantshort Inc.©</div>
                <div>QWE@HIUQWE.COM</div>
            </footer>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import MHeader from "../components/MHeader/index.vue";
export default {
    name: "home",
    data() {
        return {
            headerSwiperOptions: {
                slidesPerView: "auto",
                spaceBetween: 15,
                centeredSlides: true,
                loop: true,
                pagination: {
                    el: ".swiper-pagination"
                }
            },
            bannerArr: [],
            NRSwiperOptions: {
                slidesPerView: "auto",
                spaceBetween: 12,
                centeredSlides: true,
                slidesOffsetBefore: -10
            },
            NRArr: [
                { title: "CEO’s Substitute Wife", info: "Framed and abandoned, Alice, the adopted daughter of the influential Dunn family..." },
                { title: "CEO’s Substitute Wife", info: "Framed and abandoned, Alice, the adopted daughter of the influential Dunn family..." },
                { title: "CEO’s Substitute Wife", info: "Framed and abandoned, Alice, the adopted daughter of the influential Dunn family..." }
            ],
            CSSwiperOptions: {
                slidesPerView: 1,
                loop: true,
                pagination: {
                    el: ".swiper-pagination"
                }
            },
            CSArr: [
                { title: "Surprise Vows with My CEO Husband", info: "Katherine uncovers her fiancé's infidelity on their wedding day. Subsequent challenges, including betrayal..." },
                { title: "Surprise Vows with My CEO Husband", info: "Katherine uncovers her fiancé's infidelity on their wedding day. Subsequent challenges, including betrayal..." },
                { title: "Surprise Vows with My CEO Husband", info: "Katherine uncovers her fiancé's infidelity on their wedding day. Subsequent challenges, including betrayal..." },
                { title: "Surprise Vows with My CEO Husband", info: "Katherine uncovers her fiancé's infidelity on their wedding day. Subsequent challenges, including betrayal..." },
                { title: "Surprise Vows with My CEO Husband", info: "Katherine uncovers her fiancé's infidelity on their wedding day. Subsequent challenges, including betrayal..." }
            ],
            SCSwiperOptions: {
                freeMode: true,
                slidesPerView: "auto",
                spaceBetween: 15
            },
            SCArr: [{ title: "The good The good The good" }, { title: "The good The good The good" }, { title: "The good The good The good" }, { title: "The good The good The good" }, { title: "The good The good The good" }]
        };
    },
    components: {
        Swiper,
        SwiperSlide,
        MHeader
    },
    created() {
        this.queryData();
    },
    methods: {
        queryData() {
            this.$http.get("/api/index/module").then((res) => {
                if (res.success) {
                    this.bannerArr = res.data.bannerModule.programList;
                }
                console.log(res);
            });
        },
        goPlay(item) {
            this.$router.push({ path: "/play", query: item });
        }
    }
};
</script>
<style lang="scss" scoped>
$defaultC: #ffffff33;
.home {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    height: 100%;
    margin-top: 44px;
    .logo {
        width: 22px;
        height: 22px;
        margin-right: 4px;
    }
    .content {
        flex: 1;
        min-height: 0;
        overflow-y: auto;
        .headerSwiper {
            padding-bottom: 31px;
            overflow: hidden;
            .swiper {
                overflow: visible;
                .swiper-slide {
                    width: 225px;
                    height: 300px;
                    display: flex;
                    align-items: flex-end;
                }
            }
            .item {
                width: 225px;
                height: 300px;
                background: url("../images/empty-seat.png") 50% 50% no-repeat, $defaultC;
                background-size: 72px;
                transition: all 0.2s;
                border-radius: 2px;
            }
            .swiper-slide-prev,
            .swiper-slide-next {
                .item {
                    height: 267px;
                }
            }
            ::v-deep .swiper-pagination {
                bottom: -20px;
                height: 40px;
                line-height: 40px;
                .swiper-pagination-bullet {
                    width: 11px;
                    height: 3px;
                    border-radius: 10px;
                    background: #d1d1d1;
                }
                .swiper-pagination-bullet-active {
                    background: #fff;
                }
            }
        }
        .box {
            text-align: left;
            padding: 0 16px;
            overflow: hidden;
            .title {
                color: #fff;
                height: 28px;
                font-size: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .btn {
                    font-size: 18px;
                    padding-right: 26px;
                    background: url("../images/right1.png") right 50% / 26px no-repeat;
                }
            }
            .box-content {
                margin-top: 14px;
            }
            & + .box {
                margin-top: 18px;
            }
        }

        .NewRelease {
            .swiper {
                overflow: visible;
                .swiper-slide {
                    width: 315px;
                    height: 172px;
                    border-radius: 16px;
                    padding: 12px;
                    &:nth-child(1) {
                        background: linear-gradient(180deg, #ac85ff 0%, #b15afe 100%);
                    }
                    &:nth-child(2) {
                        background: linear-gradient(180deg, #7cc3a8 0%, #2391af 100%);
                    }
                    &:nth-child(3) {
                        background: linear-gradient(180deg, #fbd5aa 0%, #f97c80 100%);
                    }
                    .item {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        .intr {
                            flex: 1;
                            text-align: left;
                            margin-left: 4px;
                            margin-top: 8px;
                            margin-bottom: 4;
                            margin-right: 10px;
                            .title {
                                font-size: 16px;
                                height: 38px;
                                line-height: 19.02px;
                                font-weight: bold;
                            }
                            .info {
                                font-size: 12px;
                                margin-top: 8px;
                                color: #000;
                                line-height: 16.8px;
                                height: 51px;
                                flex: 1;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 3;
                                overflow: hidden;
                                margin-bottom: 11px;
                            }
                            .btn {
                                color: #f43170;
                                width: 85px;
                                height: 28px;
                                line-height: 28px;
                                text-align: center;
                                border-radius: 8px;
                                background: #fff;
                                font-size: 14px;
                            }
                        }
                        .poster {
                            width: 110px;
                            height: 100%;
                            background: url("../images/empty-seat.png") 50% 50% no-repeat, $defaultC;
                            background-size: 35px;
                            border-radius: 5px;
                        }
                    }
                }
            }
        }

        .ComingSoon {
            padding: 0;
            .title {
                padding: 0 16px;
            }
            .swiper {
                overflow: visible;
                .swiper-slide {
                    width: 375px;
                    height: 375px;
                    background: url("../images/empty-seat.png") 50% 50% no-repeat, $defaultC;
                    background-size: 87.5px;
                    border-radius: 5px;
                    .item {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        .intr {
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            height: 147px;
                            background: linear-gradient(180deg, #0000000f 0%, #00000080 100%);
                            padding: 15px 22px;
                            .title {
                                height: 25px;
                                line-height: 25px;
                                font-size: 18px;
                                font-weight: bold;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                            }
                            .info {
                                height: 34px;
                                font-size: 12px;
                                line-height: 16.8px;
                                color: #fff;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                                overflow: hidden;
                                margin-top: 1px;
                            }
                            .releaseTime {
                                margin-top: 10px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                span {
                                    display: inline-block;
                                    &:nth-child(1) {
                                        height: 32px;
                                        width: 158px;
                                        line-height: 32px;
                                        font-size: 14px;
                                        border-radius: 8px;
                                        background: #ffffff4d;
                                        text-align: center;
                                        color: #fff;
                                    }
                                    &:nth-child(2) {
                                        margin-left: 10px;
                                        width: 22px;
                                        height: 22px;
                                        background: url("../images/collect.png") 0 0 / cover no-repeat;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            ::v-deep .swiper-pagination {
                bottom: 5px;
                height: 40px;
                line-height: 40px;
                .swiper-pagination-bullet-active {
                    background: #fff;
                }
            }
        }

        .LAFS {
            margin-top: 18px;
            width: 100%;
            height: 645px;
            border-radius: 10px;
            padding: 0 16px;
            .LAFS_top {
                height: 227px;
                padding-bottom: 14px;
                display: flex;
                & > div {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    &:nth-child(1) {
                        width: 174px;
                        font-size: 12px;
                        p {
                            line-height: 16.8px;
                        }
                        .title {
                            margin-bottom: 7px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                        .info {
                            margin-bottom: 32px;
                        }
                        .more {
                            width: 54px;
                            height: 25px;
                            border-radius: 5px;
                            color: #000000b2;
                            line-height: 25px;
                            padding: 0 4px;
                            text-align: center;
                            background: #d9d9d9;
                        }
                    }
                    &:nth-child(2) {
                        flex: 1;
                        img {
                            height: 126px;
                        }
                    }
                }
            }
            .LAFS_bottom {
                border-top: 1px solid #ffffff1a;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                gap: 16px;
                padding-top: 14px;
                & > div {
                    width: 102px;
                    div {
                        height: 136px;
                        background: url("../images/empty-seat.png") 50% 50% no-repeat, $defaultC;
                        background-size: 32.53px;
                        border-radius: 5px;
                    }
                    p {
                        height: 42px;
                        margin-top: 8px;
                        color: #fff;
                        font-size: 14px;
                        line-height: 21px;
                        text-align: left;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                    }
                }
            }
        }
        .LAFS1 {
            background: url("../images/LAFS1.png") 22px 14px / 343px no-repeat, linear-gradient(179.82deg, #1f1f6e 0%, #6a5973 100%);
        }

        .LAFS2 {
            background: url("../images/LAFS2.png") 22px 14px / 343px no-repeat, linear-gradient(180deg, #bfafa3 0%, #503e26 100%);
        }

        .SecondChance {
            margin-top: 18px;
            .swiper {
                overflow: visible;
                .swiper-slide {
                    width: 138px;
                    flex-direction: column;
                    .item {
                        background: url("../images/empty-seat.png") 50% 50% no-repeat, $defaultC;
                        background-size: 44px;
                        border-radius: 5px;
                        width: 100%;
                        height: 184px;
                        position: relative;
                    }
                    .title {
                        margin-top: 8px;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 19.6px;
                        text-align: left;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        height: 40px;
                    }
                }
            }
        }

        .MoreRecommended {
            text-align: center;
            .box-content {
                display: flex;
                flex-wrap: wrap;
                gap: 15px;
                & > div {
                    width: 162px;
                    .poster {
                        background: url("../images/empty-seat.png") 50% 50% no-repeat, $defaultC;
                        background-size: 51.66px;
                        border-radius: 5px;
                        width: 162px;
                        height: 216px;
                    }
                    p {
                        height: 40px;
                        margin-top: 8px;
                        font-size: 14px;
                        line-height: 19.6px;
                        text-align: left;
                    }
                }
            }
            .more {
                margin-top: 22px;
                display: inline-block;
                width: 80px;
                height: 26px;
                line-height: 26px;
                border-radius: 5px;
                font-size: 12px;
                font-weight: 500;
                text-align: center;
                background: $defaultC;
            }
        }
    }

    footer {
        font-size: 12px;
        font-weight: 500;
        line-height: 16.8px;
        color: #ffffff80;
        text-align: center;
        margin-top: 34px;
        margin-bottom: 18px;
        & > div {
            &:nth-child(1) {
                display: flex;
                justify-content: center;
                gap: 34px;
                margin-bottom: 18px;
            }
        }
    }
}
</style>
