import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
Vue.config.devtools = true;
Vue.config.productionTip = false;
import request from "./request";
Vue.prototype.$http = request;
import "amfe-flexible";
new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount("#app");
